@import 'config.scss';

.SingleAnimation {


  &.regular {
    width: calc(100% - 50px);
    max-width: 850px;
  }

  .hideOnDesktop {
    display: block;
    @include breakpoint($md) {
      display: none;
    }
  }

  .hideOnMobile {
    display: none;

    @include breakpoint($md) {
      display: block;
    }
  }
}
.margin {
  margin-top: 40px;

  @include breakpoint($md) {
    margin-top: 64px;
  }
}
.strapi5Margin {
  margin-top: 56px;
  @include breakpoint($md) {
    margin-top: 96px;
  }
}
.strapi5MarginIndex2 {
  margin-top: 20px;
  @include breakpoint($md) {
    margin-top: 96px;
  }
}
