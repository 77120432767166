@import 'config.scss';

.PreviewBanner {
  position: fixed;
  top: 0;
  margin-top: 80px;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  color: $white;
  background-color: $bay-blue;
  opacity: 0.9;
  font-weight: $medium;
  z-index: 10;
}

.link {
  color: $golden-yellow;
  font-weight: $semi-bold;
  text-decoration: none;
  margin: 0 5px;

  &:hover {
    text-decoration: underline;
  }
}
