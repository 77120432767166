@import 'config.scss';

.LabelTitleText {
  opacity: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 56px;

  .cliContainer {
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
  }

  &.noAnimation {
    opacity: 1;

    .word,
    .button {
      opacity: 1;
    }
  }

  &.center {
    text-align: center;

    .label,
    .title,
    .text,
    .link {
      text-align: center;
    }

    .label,
    .title,
    .text {
      margin-left: auto;
      margin-right: auto;
    }

    .text {
      max-width: 750px;
      margin-left: auto;
      margin-right: auto;
      color: $grey-300;
    }

    .links {
      justify-content: center;
    }
  }

  &.small {
    .title {
      margin-bottom: 12px;
    }

    .text {
      margin-bottom: 39px;
    }
  }

  &.ctaBanner {
    .title {
      margin-bottom: 24px;
    }

    .text {
      margin-bottom: 40px;
    }
  }

  &.mobileCenter {
    .label,
    .title,
    .text,
    .link {
      text-align: center;

      @include breakpoint($md) {
        text-align: left;
      }
    }

    .links {
      justify-content: center;

      @include breakpoint($md) {
        justify-content: flex-start;
      }
    }
  }
}

.label,
.title,
.text {
  text-align: left;
  transform: translateZ(1000px);
}

.label {
  margin-bottom: 40px;
}

.title {
  position: relative;
  margin-bottom: 35px;
}

.word,
.button {
  opacity: 0;
  will-change: opacity, transform;
}

.titleContainer {
  position: relative;
}

.text {
  padding: 0 6px;

  @include breakpoint($md) {
    padding: 0;
  }
}

.label,
.title,
.text,
.links {
  &:last-child {
    margin-bottom: 0px;
  }
}

.links {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  flex-direction: column;

  @include breakpoint($sm) {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .alignUnderButton {
    flex-basis: 100%;
    margin-top: 25px;

    @include breakpoint($md) {
      margin-top: 35px;
    }

    .smallTextWithLink {
      display: inline-block;
      width: auto;
    }
  }
}

.button {
  width: 100%;

  @include breakpoint($sm) {
    width: auto;
  }
}

.link {
  margin: 0 auto 15px 0;
  width: 100%;

  @include breakpoint($sm) {
    width: auto;
    margin-right: 35px;
    margin-bottom: 0px;
  }

  &:last-child {
    margin-bottom: 0px;

    @include breakpoint($sm) {
      margin-right: 0px;
    }
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.featuresRow {
  flex-wrap: wrap;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include breakpoint($md) {
    flex-direction: row;
    margin-top: 8px;
  }
}
.oneFeature {
  display: flex;
  gap: 6px;
  align-items: center;
}
.featureIcon {
  position: sticky;
  width: 20px;
  height: 20px;
}
.featureTitle {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  color: white;
}
.container {
  width: fit-content;
  margin: 0 auto;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.btn {
  width: 100%;
}
.orContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.orText {
  color: $grey-700;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 8px;
}
.divider {
  height: 1px;
  flex: 1 0 0;
  background: $grey-700;
}
.buttonClass {
  margin-top: 20px;
  display: none;
  margin: 0 auto;
  padding: 10px 32px;
  font-size: 15px;
  width: 100%;
  gap: 4px;
  display: flex;
  font-weight: 600;
  justify-items: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 44px;
}
