@import 'config.scss';

.strapi5Wrapper {
  border-radius: 10px;
  padding: 8px 16px 8px 12px;
  border: 1px solid #32324d;
  background: #212134;
  width: fit-content;
  margin: 0 auto;
  box-shadow: 0px 4px 12px 0px rgba(41, 40, 117, 0.08);
  display: flex;
  flex-direction: column;
  @include breakpoint($md) {
   flex-direction: row;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .marginBottom {
  margin-bottom: 24px;
  }

  .tag {
    color: $white;
    font-weight: $semi-bold;
    font-size: $text-smaller;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 10px;
  }
  .tagPink {
    background-color: $purple-600;
  }

  .tagLavender {
    background-color: $lavender-500;
  }

  .link {
    color: $lavender-300;
    text-decoration: none;
    font-size: $text-legend;
    font-weight: $medium;
    margin-left: 4px;
  }
  .linkPink {
    color: $purple-600;
  }

  .linkLavender {
    color: $lavender-500;
  }
  .text {
    font-weight: 600;
  }
  p {
    font-size: $text-legend;
    line-height: normal;
  }

  @include breakpoint($md) {
    .link {
      font-size: $text-smaller;
    }

    p {
      font-size: $text-smaller;
    }

    .tag {
      margin-right: 12px;
    }
  }
}
