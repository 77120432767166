@import 'config.scss';

.ClientLogo {
  position: relative;

  width: 90%;
  max-width: 90px;
  height: 90px;
  flex-shrink: 0;
  flex-grow: 0;
  display: block;

  &.animateOnHover {
    &:hover {
      .greyLogo {
        opacity: 0;
      }
      .coloredLogo {
        opacity: 1;
      }
    }
  }
}
.margin {
  margin: 15px 20px;
}
.logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s linear;
}

.greyLogo {
  opacity: 1;
}

.coloredLogo {
  opacity: 0;

  &.coloredOnly {
    opacity: 1;
  }
}
