@import 'config.scss';

@include breakpoint($md) {
}

.empty {
  // in case of dark mode, page background is going to the top (under header)
  padding-top: $slice-spacing;
}

.slice {
  margin-top: $slice-spacing;
}
