@import 'config.scss';

.Hero {
  margin-bottom: 83px;
  transform: translateZ(0);

  .clientsLogos {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 32px;
    justify-content: center;
  }

  @include breakpoint($md) {
    .clientsLogos {
      padding: 0 84px 64px 84px;
    }
  }
}

.heroWrapper {
  z-index: 1;
  padding: 104px 0 32px 0;

  h1 {
    max-width: 740px;
    margin-bottom: 16px;
    margin-top: 24px;
    text-align: left;
  }

  h2 {
    color: $grey-400;
    margin-bottom: 40px;
    text-align: left;
  }

  .animations {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    justify-items: center;
    .singleAnimation {
      figure {
        svg {
          border: 6px solid rgba(255, 255, 255, 0.4);
          border-radius: 4px;
        }
      }
    }
  }

  @include breakpoint($md) {
    padding: 120px 0;
    h1 {
      margin-top: 48px;
      margin-bottom: 32px;
      text-align: center;
    }
    h2 {
      margin-bottom: 48px;
      text-align: center;
    }
    .animations {
      grid-template-columns: 1.83fr 1fr;
    }
  }
}

.text {
  max-width: 400px;
}

.arrowWrapper {
  display: none;
  position: absolute;
  bottom: 170px;

  @include breakpoint($md) {
    display: block;
  }
}

.background {
  display: flex;
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #121180;

  .topRightImage {
    display: block;
    position: absolute;
    right: 0;
    z-index: 1;
    object-fit: cover;
    top: 80px;
    width: 100%;
    height: 500px;
  }

  .topLeftImage {
    display: none;
    position: absolute;
    left: 0;
    z-index: 1;
    object-fit: cover;
  }
  .imgWrap {
    width: 860px;
    height: 540px;
    position: relative;
  }

  .topLeftImageWrap {
  }

  @include breakpoint($md) {
    .topRightImage {
      top: 0;
      width: fit-content;
    }
    .topLeftImage {
      display: block;
    }
  }
}
